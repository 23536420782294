import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Laser Cutting",
  "description": "Cut metals and plastics with high precision.",
  "author": "Orkun Zafer Ozturk",
  "categories": ["mechanical"],
  "date": "2022-01-01T00:00:00.000Z",
  "featured": false,
  "tags": ["", "", "", "", ""],
  "relatedArticles": ["", ""]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Along with CNC, laser cutting is one of the most popular subtractive manufacturing methods out there. The process is simple. A focused laser beam burns through a given set of coordinates, similar to CNC. This set of coordinates is called G-Code and usually comes in .gcode or .nc file formats. If you don’t have this file, you will first need to run your .step files through “slicer software”. We use `}<a parentName="p" {...{
        "href": "https://ultimaker.com/software/ultimaker-cura"
      }}>{`Cura`}</a>{`.`}</p>
    <p>{`Laser cutting allows for cutting metal and plastic plates into shapes that would otherwise be very challenging. Since laser cutting is very versatile, we use them both in volume manufacturing and prototyping alike.
Typical material thickness for laser cutting would be less than `}<strong parentName="p">{`10mm`}</strong>{`. Our machinery supports surfaces up to `}<strong parentName="p">{`80*50 cm`}</strong>{`.  While we handle non-metal laser cutting in-house (i.e acrylic), we handle metal laser cutting in our authorized vendor facilities.  `}</p>
    <p>{`Good laser cutters are generally more accessible than high precision CNC machinery, which is why your local university, maker space, or company may have one in store.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      